import React, { useEffect, useState } from 'react';
import {
  MenuOutlined ,
  CommentOutlined,
  HomeOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import { Layout, Menu, Button, theme } from 'antd';
import Logo from '../../Assets/logo.png'
import LogoCollapse from '../../Assets/star.png'
import {  useNavigate, useLocation } from 'react-router-dom';
import PatientProfile from '../PatientProfile/PatientProfile';
import { FaFlaskVial } from "react-icons/fa6";
import { TbReportSearch } from "react-icons/tb";
import { FaHeartbeat } from "react-icons/fa";
import { FaStaffSnake } from "react-icons/fa6";
import { useIdle } from "@uidotdev/usehooks";
import { useUser } from '../../context/UserContext';
const { Header, Sider, Content } = Layout;

const items = [
  {
    key: '/dashboard',
    icon: <HomeOutlined />,
    label: 'Home',
  },
  {
    key:'/vital-signs',
    icon: <FaHeartbeat/>,
    label:'Basic Vitals'
  },
  {
    key: '/medications',
    icon: <FaStaffSnake />,
    label: 'Prescriptions',
  },
  {
    key: '/ordered-labs',
    icon: <FaFlaskVial />,
    label: 'Ordered Labs',
  },
  {
    key: '/lab-results',
    icon: <TbReportSearch />,
    label: 'Lab Results',
  },
  {
      key: '/providers-comments',
      icon: <CommentOutlined/>,
      label: "Provider's Comments",
    },
]

const App = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { appLogoData , getAppLogo} = useUser();
  const [collapsed, setCollapsed] = useState(false);
  const idle = useIdle(600000);

  const handleLogout = ()=>{
    localStorage.clear()
    sessionStorage.clear()
    navigate("/")
  }

  useEffect(() => {
    if (idle) {
      handleLogout()
    } 
  }, [idle]);

  
  useEffect(()=>{
    getAppLogo()
  },[])

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout className='h-screen'>
      <Sider trigger={null} collapsible collapsed={collapsed} width={256}
       style={{
        position:'fixed',
        height:'100vh'
      }}
      >
      <div
          className="flex justify-center brand"
          style={{
            transition: "margin-left 0.2s",
          }}
        >
          {collapsed ? (
            <img src={LogoCollapse} alt="Logo" className="w-[3rem] my-5 logo" />
          ) : (
            <img src={appLogoData?.icon || Logo} alt="Logo" className="w-[10rem] my-5" />
          )}
        </div>
        <Menu
        
          theme="dark"
          mode="inline"
          className='mt-2'
          defaultSelectedKeys={['1']}
          selectedKeys={[location.pathname]}
          onClick={({ key }) => navigate(key)}
          items={items}
        />
        <div className="p-4 absolute bottom-0 w-full flex justify-center items-center">
          <Button
            type="primary"
            icon={<LogoutOutlined />}
            onClick={handleLogout}
          >
            Logout
          </Button>
        </div>
      </Sider>
      <Layout>
        <Header 
          style={{
            padding: 0,
            background: colorBgContainer,
            width: collapsed ? "calc(100% - 80px)" : "calc(100% - 256px)",
            position: "fixed",
            minWidth: collapsed ? "calc(100% - 80px)" : "calc(100% - 256px)",
            marginLeft: collapsed ? "80px" : "256px",
            zIndex: 29,
            transition: "margin-left 0.2s",
          }}
          className="header-class lg:ml-80px lg:fixed lg:top-0 lg:w-[calc(100%-80px)] shadow-sm"
        >
           <PatientProfile collapsed={collapsed} toggleSidebar={() => setCollapsed(!collapsed)}/>
        </Header>
        <Content
          style={{
            padding: 24,
            // minHeight: '100vh',
            marginLeft: collapsed ? "80px" : "256px",
            background: colorBgContainer,
            marginTop: "64px",
          }}
          className="lg:ml-80px mt-[80px]"
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
};
export default App;